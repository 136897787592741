import axios from 'axios';
import Config from './Config';
import { printLogs } from './Service';
import { ContactUsType } from '@/Interface/interface';

export async function getDevicesAPI(params: string, countryCode?: string) {
  try {
    return await Config(`/devices${params}`, 'get', '', false, countryCode);
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getSearchDevicesAPI(
  bodyParams: string,
  countryCode?: string,
) {
  try {
    return await Config(
      `/devices/search`,
      'post',
      JSON.parse(bodyParams),
      false,
      countryCode,
    );
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getBrandAPI(countryCode: string) {
  try {
    return await Config(`/brands`, 'get', '', false, countryCode);
  } catch (err) {
    return printLogs('err', err);
  }
}

export async function getPopularComparisons(slug: string = '') {
  try {
    return await Config(`/popular-comparisons${slug}`, 'get', '', false);
  } catch (err) {
    return printLogs('err', err);
  }
}

export async function getBlogAPI() {
  try {
    return (await Config(`/blog/feed/`, 'get', '', true)).data;
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getSitemap() {
  try {
    return (await Config(`/sitemap/index.xml`, 'get', '', false)).data;
  } catch (err) {
    return printLogs('err', err);
  }
}

export async function getContactApi(contactUs: ContactUsType) {
  try {
    return await Config(`/contact-uses`, 'post', contactUs, false);
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getHomePageBanner() {
  try {
    return await Config(`/home-page-banners`, 'get', '', false);
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getAllDeviceApi() {
  try {
    return await Config(`/alldevice`, 'get', '', false);
  } catch (err) {
    return printLogs('err', err);
  }
}
export async function getAllSlugHistory() {
  try {
    return await Config(`/slug-histories`, 'get', '', false);
  } catch (err) {
    return printLogs('err', err);
  }
}
