import { NextRouter, useRouter } from 'next/router';
import { currencyLocaleType, featureArr } from './array';
import moment from 'moment';
import React from 'react';
import {
  batteryInformationForOverviewField,
  cameraInformationForOverviewField,
  displayInformationForOverviewField,
  getBodySpecification,
  getBodyWeight,
  getChargingSpecification,
  getChipSetSpecification,
  getColorAndSoundData,
  getCommunicationPositioningInformation,
  getCoverDisplaySpecification,
  getDeviceFeatureAndSensors,
  getDeviceOtherFeatures,
  getDisplayProtectionInformation,
  getDisplaySpecification,
  getExtraOtherDataForDisplay,
  getFilteredCameraAndColorFeatures,
  getMemoryInformation,
  getNetworkFieldsData,
  getOSSpecification,
  getPlatformCPUInformation,
  getVideoInformation,
  OSInformationForOverviewField,
} from '@/utils/device-specification';
import {
  KeyAndValue,
  MainCameraData,
  MainCameraVideo,
  MemorySpecificationArrayType,
  PerformanceFieldArrayType,
  PerformanceKeyAndData,
  SelfieCameraData,
  selfieCameraSpecificationArrayType,
  DeviceTags,
  DisplayTagNames,
} from '@/Interface/interface';
export const printLogs = (name: string, value: any) => {
  if (typeof window !== 'undefined')
    if (window.location.href.includes('localhost')) {
      console.log(name, value);
    }
};
const getCurrentYear = new Date().getFullYear();
export const getKeywords = (deviceName: string) => {
  return `${deviceName} mobile phones in India, ${deviceName} Mobile Phones in United Kingdom, ${deviceName} Phones in USA, ${deviceName} Mobiles in Canada, ${deviceName} Smartphones in Brazil, ${deviceName} Device in Europe, ${deviceName} mobile in England, ${deviceName}, ${deviceName} Price, ${deviceName} specifications, ${deviceName} specs, ${deviceName} release date, ${deviceName} launch, ${deviceName} launch date, ${deviceName} features, ${deviceName} review, ${deviceName} price in ${getCurrentYear},${deviceName} Images, ${deviceName} information, ${deviceName} details`;
};

export const getBrandHeading = (brandNames: string[]) => {
  return `${brandNames.join(', ')} Mobile Phones Price List (${new Date().toLocaleString(
    'default',
    { month: 'short' },
  )} ${new Date().getFullYear()})`;
};

export const getBrandDescription = (brandNames: string[], count: number) => {
  return `Explore ${count} ${brandNames.join(', ')} smartphones with detailed
    specifications, prices, and features. Whether you're looking for
    flagship models or budget-friendly options.`;
};

//scroll to top
export const scrollToElement = (ref: any) => {
  if (ref !== null) {
    ref?.current?.scrollIntoView({
      block: 'start',
    });
  }
};

//convert date in to specific days ago (from latest blog date)
export const getTime = (date: string) => {
  let result = moment(date).fromNow();
  const now = moment();
  const days = now.diff(date, 'days');
  const weeks = now.diff(date, 'weeks');
  if (days >= 7) {
    if (days <= 13) {
      result = `${weeks} week ago`;
    } else if (days > 13 && days <= 25) {
      result = `${weeks} weeks ago`;
    }
  } else {
    if (days === 1) {
      result = `${days} days ago`;
    }
  }
  return result;
};
//compare page heading from url
export const getHeading = (link: any) => {
  if (link) {
    return link
      ?.split('-')
      ?.map((word: string) =>
        word === 'vs' ? 'vs' : word.charAt(0).toUpperCase() + word.slice(1),
      )
      ?.join(' ');
  }
  return '';
};

export const isRouteNotComparePhones = (path: string, router: NextRouter) => {
  return path !== null && path?.includes('compare-phones')
    ? typeof window !== 'undefined'
      ? router.asPath !== '/compare-phones/'
        ? window.location.href.split('/').reverse()[0] === '' &&
          window.location.href.split('/').reverse()[1] === 'compare-phones'
          ? false
          : (window.location.href.split('/').reverse()[0] === '' &&
                window.location.href.split('/').reverse()[1] !==
                  'compare-phones') ||
              window.location.href.split('/').reverse()[0] !== 'compare-phones'
            ? true
            : false
        : window.location.href.split('/').reverse()[0] !== '' &&
            window.location.href.split('/').reverse()[1] === 'compare-phones'
          ? true
          : false
      : path !== '/compare-phones/'
        ? true
        : false
    : true;
};
//check is any field is blank remove that field from table (compare table and feature table)
export function areAllFieldNull(value: any, index: number) {
  if (
    /^[\s,]*$/g.test(value) ||
    value === null ||
    value === undefined ||
    value === '' ||
    /^[ -]*$/.test(value) ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return index;
  }
}
export function convertToMBOrTB(num: number) {
  const sizeInMB = num * 1024;
  if (num !== 0) {
    if (num < 1) {
      return sizeInMB + 'MB';
    } else if (num >= 1024) {
      const sizeInTB = num / 1024;
      return sizeInTB + 'TB';
    } else {
      return num + 'GB';
    }
  } else {
    return '';
  }
}

// price converter function

export function numberRoundUp(number: number) {
  const numDigits = Math.floor(Math.log10(number)) + 1;
  let digitOfNumber;

  switch (numDigits) {
    case 1:
      digitOfNumber = 10;
      break;
    case 2:
      digitOfNumber = 10;
      break;
    case 3:
      digitOfNumber = 100;
      break;
    case 4:
      digitOfNumber = 100;
      break;
    case 5:
      digitOfNumber = 1000;
      break;
    case 6:
      digitOfNumber = 1000;
      break;
    default:
      digitOfNumber = 1000;
  }

  let roundedNumber = Math.ceil(number / digitOfNumber) * digitOfNumber - 1;
  return Math.floor(roundedNumber);
}
//resolution in compare table and feature table show highest resolution
export const getHighestResolution = (item: MainCameraVideo[] | undefined) => {
  let highestResolution = 0;
  item?.forEach((camera) => {
    const resolution = camera.resolution || 0;
    if (resolution > highestResolution) {
      highestResolution = resolution;
    }
  });
  return highestResolution;
};

export const getCameraResolution = (item: any) => {
  let highestResolution: any = [];
  item?.map((camera: { resolution: number }) => {
    const resolution = camera.resolution || 0;
    highestResolution.push(resolution);
    return resolution;
  });
  const getResolution = highestResolution.join('+');
  return getResolution;
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//meta url
export function removeTrailingSlashes(str: string) {
  // Remove trailing slashes
  str = str.replace(/\/+$/, '');
  return str;
}

export const getCurrentURL = () => {
  if (typeof window !== 'undefined') {
    return (window as Window).location.href;
  }
  const router = useRouter();
  return (process.env.BASE_URL + router.asPath).replace(/([^:]\/)\/+/g, "$1");
};

export function moveObjectToLast(
  deviceSpecificationArray: any,
  indexToMove: number,
) {
  const objectToMove = deviceSpecificationArray[indexToMove];
  if (objectToMove) {
    deviceSpecificationArray.splice(indexToMove, 1); // Remove the object from the original position
    deviceSpecificationArray.push(objectToMove); // Push the object to the last position
  }
  return deviceSpecificationArray;
}
//convert price formate from currency code
export function getStateWiseCurrencyFormate(
  price: number,
  currencyType: string,
) {
  return currencyType !== 'BRL'
    ? new Intl.NumberFormat(currencyLocaleType[currencyType]?.code, {
        style: 'currency',
        currency: currencyType,
        minimumFractionDigits: 0,
      }).format(price)
    : new Intl.NumberFormat(currencyLocaleType[currencyType]?.code, {
        style: 'currency',
        currency: currencyType,
        minimumFractionDigits: 0,
      })
        .format(price)
        .replace('.', ',');
}

export function moveObjectToFirst(
  newArr: any,
  oldArr: any,
  length: number,
  id: number,
) {
  let newArrData = [...newArr];
  let oldArrData = [...oldArr];
  newArrData[length] = oldArrData[id];
  newArrData[id] = oldArrData[length];
  return newArrData;
}
export const convertIntoString = (value: string | number) => {
  return !isNaN(Number(value))
    ? value
    : String(value)?.includes('-')
      ? String(value)
          .split('-')
          .map((res) => res)
          .join('_')
          .toLowerCase()
      : String(value).toLowerCase();
};
export function isEmpty(value: any) {
  return (
    // value === null ||
    // value === undefined ||
    // value === "" ||
    // (Array.isArray(value) && value.length === 0)
    /^[\s,]*$/g.test(value) ||
    value === null ||
    value === undefined ||
    value === '' ||
    /^[ -]*$/.test(value) ||
    (Array.isArray(value) && value.length === 0)
  );
}
export const isArrBlank = (res: string[] | string) => {
  if (String(res) === '' || res === undefined) {
    return null;
  } else {
    return res;
  }
};
//find data from params and key for features of phone
export const getBodyParams = (params: string, key: string) => {
  return isArrBlank(
    params
      .replaceAll('+', ' ')
      .replaceAll('%2B', '+')
      .split('/')
      .filter((res: string, index: number) => index > 1)
      .filter((res: string) => res?.includes(key))
      .map((item: string) =>
        item
          .split('-')
          .filter((res: string) => res !== item.split('-').reverse()[0]),
      )[0],
  );
};
//get updated url
export const getUpdatedPath = (path: string, num: number) => {
  return path
    .split('/')
    .filter((path, index) => index > num)
    .map((res: string) =>
      res.split('-').reverse()[0] !== 'battery_capacity' &&
      res.split('-').reverse()[0] !== 'display_screen_size' &&
      res.split('-').reverse()[0] !== 'price'
        ? res
            .split('-')
            .filter((item: string) => item !== res.split('-').reverse()[0])
            .map((item: string) => ({
              label:
                res.split('-').reverse()[0] !== 'brand'
                  ? res.split('-').reverse()[0]
                  : 'brand_name',
              value: !isNaN(Number(item))
                ? Number(item)
                : item.replaceAll('+', ' ').replaceAll('%2B', '+'),
              match: !isNaN(Number(item))
                ? Number(item)
                : item.replaceAll('+', ' ').replaceAll('%2B', '+'),
            }))
        : Array(
            res
              .split('-')
              .filter((item: string) => item !== res.split('-').reverse()[0])
              .join('-'),
          ).map((item: string) => ({
            label: res.split('-').reverse()[0],
            value: !isNaN(Number(item))
              ? Number(item)
              : item.replaceAll('+', ' ').replaceAll('%2B', '+'),
            match: !isNaN(Number(item))
              ? Number(item)
              : item.replaceAll('+', ' ').replaceAll('%2B', '+'),
          })),
    );
};
export const removeNull = (value: any, sign?: string) => {
  if (
    value !== null &&
    value !== 0 &&
    value !== undefined &&
    value !== '' &&
    value === true
  ) {
    return sign;
  }
  if (
    value !== null &&
    value !== 0 &&
    value !== undefined &&
    value !== '' &&
    value === false
  ) {
    return '';
  } else if (
    value !== null &&
    value !== 0 &&
    value !== undefined &&
    value !== ''
  ) {
    return sign !== '' && sign !== undefined ? value + sign : value;
  } else {
    return '';
  }
};

export const scrollToTop = (body: any) => {
  if (body)
    body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
};

export const convertPriceByCurrency = (price: any) => {
  return price &&
    price[String(Object.keys(price))] !== undefined &&
    price[String(Object.keys(price))] !== null
    ? getStateWiseCurrencyFormate(
        parseInt(String(price[String(Object.keys(price))])),
        String(Object.keys(price)),
      )
    : '';
};

export const getHexCodeFromTagItem = (tagItem: string) => {
  let tagNameAndColor = undefined;
  switch (tagItem) {
    case DeviceTags.POPULAR_BADGE:
      tagNameAndColor = { tagName: DisplayTagNames.POPULAR, color: '#098409' };
      break;
    case DeviceTags.UPCOMING_BADGE:
      tagNameAndColor = { tagName: DisplayTagNames.UPCOMING, color: '#DEDB21' };
      break;
    case DeviceTags.DISCONTINUE_BADGE:
      tagNameAndColor = {
        tagName: DisplayTagNames.DISCONTINUE,
        color: '#C12D29',
      };
      break;
    case DeviceTags.RUMORED_BADGE:
      tagNameAndColor = { tagName: DisplayTagNames.RUMORED, color: '#ffb733' };
      break;
    default:
      break;
  }
  return tagNameAndColor;
};

//convert object in to string (features)
export const getStringFormateData = (
  deviceSpecificationArray: KeyAndValue[],
  key: string,
): string | number | boolean => {
  return deviceSpecificationArray?.filter((res) => res.key === key)[0]
    ?.value !== null &&
    deviceSpecificationArray?.filter((res) => res.key === key)[0]?.value !==
      undefined
    ? deviceSpecificationArray?.filter((res) => res.key === key)[0]?.value
    : '';
};

const communicationDataFormatter = (
  communicationDataArray: KeyAndValue[],
  formatArray: any,
) => {
  return formatArray
    .map((arrayObj: any) => {
      const defaultValue = Object.values(arrayObj)[0];
      const actualValue = communicationDataArray?.filter(
        (res: { key: string }) => res?.key === Object.keys(arrayObj)[0],
      )[0]?.value;

      if (actualValue && actualValue?.toString().trim().length > 0) {
        if (defaultValue) {
          return defaultValue;
        } else {
          return actualValue?.toString().trim();
        }
      }
      return null;
    })
    .filter((arrayKeysValue: any) => {
      if (arrayKeysValue !== null || arrayKeysValue !== ' ') {
        return arrayKeysValue;
      }
    })
    .join(', ');
};

const launchDateFormatter = (
  launchDateArray: KeyAndValue[],
  arrayFormat: any,
  isReleasedDate: boolean,
) => {
  const dateArray = arrayFormat
    .map((key: string) => {
      const actualValue = launchDateArray?.find(
        (res: { key: string }) => res?.key === key,
      )?.value;

      if (actualValue && actualValue?.toString().trim().length > 0) {
        if (
          key === 'launch_announced_month' ||
          key === 'launch_released_month'
        ) {
          return new Date(2000, Number(actualValue) - 1, 1)?.toLocaleString(
            'default',
            { month: 'long' },
          );
        }
        if (key === 'launch_announced_day' || key === 'launch_released_day') {
          if (Number(actualValue) < 10) {
            return `0${actualValue}`;
          }
        }
        return actualValue?.toString().trim();
      }
      return null;
    })
    .filter((arrayKeysValue: any) => {
      if (arrayKeysValue !== null) {
        return arrayKeysValue;
      }
    });

  let days = '';
  if (dateArray.length === 3) {
    days = dateArray.pop();
  }
  if (!isReleasedDate) {
    return `${dateArray.join(', ')} ${days}`;
  } else {
    const expectedReleasedDate = launchDateArray?.find(
      (res: { key: string }) => res?.key === 'launch_expected_released_date',
    )?.value;

    const expectedReleaseValue =
      expectedReleasedDate && expectedReleasedDate.toString().trim()
        ? `(${expectedReleasedDate})`
        : '';
    return `${dateArray.join(', ')} ${days} ${expectedReleaseValue}`;
  }
};

export const getCameraResolutionCompareDevice = (
  cameraData: MainCameraData[],
) => {
  const highestResolution = getCameraResolution(cameraData);
  return highestResolution;
};
export const getSelfieCameraCompareDevice = (
  selfieCameraInformationArray: any,
  type: any,
) => {
  const data =
    selfieCameraInformationArray &&
    selfieCameraInformationArray.find((val: any) => val.key === type);
  const highestResolution = getHighestResolution(data?.value);
  return highestResolution;
};

const renderCameraSpecification = (cameraSpec: SelfieCameraData) => {
  const specs = [
    `${cameraSpec.resolution} MP`,
    cameraSpec.aperture,
    cameraSpec.lense,
    cameraSpec.sensor_size,
    cameraSpec.pixel_size,
    cameraSpec.autofocus,
    cameraSpec.image_stabalization,
  ].filter(Boolean);
  return specs.join(', ');
};

const getCameraDetails = (cameraInformationArray: any, index: number) => {
  const cameraType = cameraInformationArray.data.find(
    (res: any) => res.key === 'main_camera_type',
  );
  const details = (
    <React.Fragment key={index}>
      <ul style={{ padding: '0', margin: '0' }}>
        {cameraInformationArray.data
          ?.find((res: { key: string }) => res?.key === 'main_camera_data')
          ?.value?.map((item: string | number | any) => {
            let string = (
              <React.Fragment key={item.id}>
                <li style={{ listStyle: 'none' }}>
                  {'➤ '}
                  {renderCameraSpecification(item)}
                </li>
              </React.Fragment>
            );
            return string;
          })}
      </ul>
    </React.Fragment>
  );

  return (
    <>
      <span className="font-semibold">
        {cameraType?.value === 'None'
          ? 'None'
          : `${cameraType?.value} Camera: `}
      </span>
      {details}
    </>
  );
};

export const updateKeyName = (key: string) => {
  if (key === 'misc') {
    key = 'miscellaneous';
  } else if (key === 'comms') {
    key = 'connectivity';
  } else if (key === 'platform') {
    key = 'software & hardware';
  } else if (key === 'launch') {
    key = 'launch date';
  }
  return key;
};
export const getMemoryData = (
  memoryDataArray: MemorySpecificationArrayType[],
  key: string,
) => {
  const memory =
    memoryDataArray && memoryDataArray.find((res: any) => res.key === key);
  if (memory && memory.value && memory.value.length > 0) {
    const finalMemory = memory.value[0];
    const memory_internal_storage = finalMemory.memory_internal_storage;
    const memory_internal_ram = finalMemory.memory_internal_ram;
    const formattedMemory = `${convertToMBOrTB(
      memory_internal_ram,
    )} RAM, ${convertToMBOrTB(memory_internal_storage)}`;
    return formattedMemory;
  }
};

export const getOverviewVideo = (videoInformationArray: MainCameraVideo[]) => {
  const highestResolution = getHighestResolution(videoInformationArray);

  return removeNull(highestResolution, '')
    ? highestResolution > 1080 && highestResolution % 540 === 0
      ? `${highestResolution / 540}K`
      : `${highestResolution}P`
    : '';
};

const getSelfieCameraDetails = (
  selfieCameraSpecsArray: selfieCameraSpecificationArrayType[],
  key: string,
) => {
  return selfieCameraSpecsArray
    ?.find((res) => res?.key === key)
    ?.value?.map((item) => {
      return renderCameraSpecification(item);
    });
};

const getFormattedData = (performanceData: PerformanceKeyAndData[]) => {
  return performanceData[0]?.performance_data
    ?.map((res: any) => {
      return `${removeNull(res.performace_score, '')}${res.performace_version ? ` ${res.performace_version}` : ''}`;
    })
    .join(', ');
};

const getPerformanceData = (performanceData: PerformanceFieldArrayType[]) => {
  const findPerformanceData = performanceData[0].value.filter(
    (res) => res.performance_key,
  );
  const result: any = findPerformanceData?.reduce(
    (performanceData: any, current: any) => {
      if (!performanceData[current.performance_key]) {
        performanceData[current.performance_key] = [];
      }
      performanceData[current.performance_key].push(current);
      return performanceData;
    },
    {},
  );
  return Object?.keys(result)?.map((key: any) => ({
    key,
    value: result[key],
  }));
};

//convert object in to string (features)
export const getDeviceFeature = (deviceSpecification: any) => {
  const transformedData: any = [];
  for (const key in deviceSpecification) {
    const splitKeys = key.split('_');
    const mainKey =
      splitKeys[0] === 'front' ||
      splitKeys[0] === 'back' ||
      splitKeys[0] === 'peak' ||
      splitKeys[0] === 'hdr' ||
      splitKeys[0] === 'brightness' ||
      splitKeys[0] === 'cover'
        ? key === 'cover_camera'
          ? 'selfie'
          : 'display'
        : splitKeys[0] === 'chipset'
          ? 'platform'
          : splitKeys[0] === 'charging'
            ? 'battery'
            : splitKeys[0] === 'technology'
              ? 'network'
              : splitKeys[1] === 'features'
                ? 'features'
                : splitKeys[0] === 'test' || splitKeys[0] === 'tests'
                  ? 'misc'
                  : splitKeys[0] === 'features'
                    ? 'sensors'
                    : splitKeys[0] === 'memory'
                      ? 'storage'
                      : splitKeys[0] === 'performance'
                        ? 'performance'
                        : splitKeys[0];
    const subKey = String(splitKeys.map((res: string) => res).join('_'));
    if (
      !mainKey ||
      !subKey ||
      (mainKey !== 'network' &&
        mainKey !== 'launch' &&
        mainKey !== 'body' &&
        mainKey !== 'display' &&
        mainKey !== 'platform' &&
        mainKey !== 'storage' &&
        mainKey !== 'main' &&
        mainKey !== 'selfie' &&
        mainKey !== 'sound' &&
        mainKey !== 'comms' &&
        mainKey !== 'sensors' &&
        mainKey !== 'battery' &&
        mainKey !== 'misc' &&
        mainKey !== 'performance' &&
        mainKey !== 'tests')
    ) {
      continue;
    }
    const existingEntry = transformedData.find(
      (item: { key: string }) => item.key === mainKey,
    );
    if (existingEntry) {
      existingEntry.data.push({ key: subKey, value: deviceSpecification[key] });
    } else {
      transformedData.push({
        key: mainKey,
        data: [{ key: subKey, value: deviceSpecification[key] }],
      });
    }
  }
  let mainArr: any = [];
  for (let i = 0; i < featureArr.length; i++) {
    transformedData?.filter((resp: { key: string }) =>
      resp.key === featureArr[i] ? mainArr.push(resp) : null,
    );
  }
  let featureDataArr = mainArr?.length !== 0 ? mainArr : transformedData;
  const overViewFields = featureDataArr.reduce((key: any, res: any) => {
    key[res.key] = res.data;
    return key;
  }, {});
  const displayData = overViewFields['display'];
  const cameraData = overViewFields['main'];
  const selfieCamera = overViewFields['selfie'];
  const softwareAndHardware = overViewFields['platform'];
  const batteryData = overViewFields['battery'];
  const memoryData = overViewFields['storage'];

  const overview = {
    key: 'overview',
    data: [],
  };
  featureDataArr.unshift(overview);

  return featureDataArr?.map((res: any, index: number) => ({
    key:
      res.key === 'selfie'
        ? 'SELFIE CAMERA'
        : res.key === 'main'
          ? 'MAIN CAMERA'
          : res.key,
    data:
      res.key === 'network'
        ? getNetworkFieldsData(res)
        : res.key === 'network'
          ? [{ key: '', value: '' }]
          : res.key === 'launch'
            ? [
                {
                  key: 'Announced Date',
                  value: `${launchDateFormatter(
                    res.data,
                    [
                      'launch_announced_year',
                      'launch_announced_month',
                      'launch_announced_day',
                    ],
                    false,
                  )}`,
                },
                {
                  key: 'Released Date',
                  value: `${launchDateFormatter(
                    res.data,
                    [
                      'launch_released_year',
                      'launch_released_month',
                      'launch_released_day',
                    ],
                    true,
                  )}`,
                },
                {
                  key: 'Status',
                  value: `${removeNull(
                    getStringFormateData(res.data, 'launch_status'),
                    '',
                  )}`,
                },
              ]
            : res.key === 'body'
              ? [
                  {
                    key: 'Dimensions',
                    value: removeNull(getBodySpecification(res.data)),
                  },
                  {
                    key: 'Thickness',
                    value: removeNull(
                      getStringFormateData(res.data, 'body_dimensions_depth'),
                      '',
                    ),
                  },
                  {
                    key: 'Weight',
                    value: removeNull(getBodyWeight(res.data), ''),
                  },
                  {
                    key: 'Build',
                    value: `${removeNull(
                      getStringFormateData(
                        res.data,
                        'body_build_glass_front_unfolded',
                      ),
                      '',
                    )}${
                      removeNull(
                        getStringFormateData(
                          res.data,
                          'body_build_glass_front_unfolded',
                        ),
                        '',
                      )
                        ? removeNull(
                            getStringFormateData(
                              res.data,
                              'body_build_glass_front_folded',
                            ),
                            '',
                          )
                          ? ', '
                          : removeNull(
                                getStringFormateData(
                                  res.data,
                                  'body_build_glass_back',
                                ),
                                '',
                              )
                            ? ', '
                            : removeNull(
                                  getStringFormateData(
                                    res.data,
                                    'body_build_frame',
                                  ),
                                  '',
                                )
                              ? ', '
                              : ''
                        : ''
                    }${removeNull(
                      getStringFormateData(
                        res.data,
                        'body_build_glass_front_folded',
                      ),
                      '',
                    )}${
                      removeNull(
                        getStringFormateData(
                          res.data,
                          'body_build_glass_front_folded',
                        ),
                        '',
                      )
                        ? removeNull(
                            getStringFormateData(
                              res.data,
                              'body_build_glass_back',
                            ),
                            '',
                          )
                          ? ', '
                          : removeNull(
                                getStringFormateData(
                                  res.data,
                                  'body_build_frame',
                                ),
                                '',
                              )
                            ? ', '
                            : ''
                        : ''
                    }${removeNull(
                      getStringFormateData(res.data, 'body_build_glass_back'),
                      '',
                    )}${
                      removeNull(
                        getStringFormateData(res.data, 'body_build_glass_back'),
                        '',
                      )
                        ? removeNull(
                            getStringFormateData(res.data, 'body_build_frame'),
                            '',
                          )
                          ? ', '
                          : ''
                        : ''
                    }${removeNull(
                      getStringFormateData(res.data, 'body_build_frame'),
                      '',
                    )}
              `,
                  },
                  {
                    key: 'SIM',
                    value: removeNull(
                      getStringFormateData(res.data, 'body_sim'),
                      '',
                    )
                      ? removeNull(
                          getStringFormateData(res.data, 'body_sim'),
                          '',
                        )
                      : null,
                  },

                  {
                    key: 'Keyboard',
                    value: removeNull(
                      getStringFormateData(res.data, 'body_keyboard'),
                      '',
                    ),
                  },
                  {
                    key: 'Resistance',
                    value: `${removeNull(
                      getStringFormateData(res.data, 'body_ip_rating'),
                      `${
                        res.data
                          ?.filter(
                            (res: { key: string }) =>
                              res?.key === 'body_ip_rating',
                          )[0]
                          ?.value?.trim() === 'IP53'
                          ? ', '
                          : ''
                      }`,
                    )} ${removeNull(
                      getStringFormateData(res.data, 'body_resistant'),
                      '',
                    )}`,
                  },
                  {
                    key: 'Notification Light',
                    value: removeNull(
                      getStringFormateData(
                        res.data,
                        'body_notification_lights',
                      ),
                      '',
                    ),
                  },
                  {
                    key: 'Blinking Indicator',
                    value: removeNull(
                      getStringFormateData(res.data, 'body_blinking_indicator'),
                      '',
                    ),
                  },
                  {
                    key: 'Other',
                    value: `${removeNull(
                      getStringFormateData(res.data, 'body_other'),
                      '',
                    )}`,
                  },
                ]
              : res.key === 'display'
                ? [
                    {
                      key: 'Type',
                      value: `${removeNull(
                        getStringFormateData(res.data, 'display_type'),
                        '',
                      )}${
                        removeNull(
                          getStringFormateData(res.data, 'display_type'),
                          '',
                        )
                          ? removeNull(
                              getStringFormateData(res.data, 'hdr_support'),
                              '',
                            )
                            ? ', '
                            : removeNull(
                                  getStringFormateData(
                                    res.data,
                                    'peak_brightness',
                                  ),
                                  '',
                                )
                              ? ', '
                              : removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'brightness',
                                    ),
                                    '',
                                  )
                                ? ', '
                                : ''
                          : ''
                      }${removeNull(
                        getStringFormateData(res.data, 'hdr_support'),
                        '',
                      )}${
                        removeNull(
                          getStringFormateData(res.data, 'hdr_support'),
                          '',
                        )
                          ? removeNull(
                              getStringFormateData(res.data, 'peak_brightness'),
                              '',
                            )
                            ? ', '
                            : removeNull(
                                  getStringFormateData(res.data, 'brightness'),
                                  '',
                                )
                              ? ', '
                              : ''
                          : ''
                      }${removeNull(
                        getStringFormateData(res.data, 'peak_brightness'),
                        '',
                      )}${
                        removeNull(
                          getStringFormateData(res.data, 'peak_brightness'),
                          '',
                        )
                          ? removeNull(
                              getStringFormateData(res.data, 'brightness'),
                              '',
                            )
                            ? ', '
                            : ''
                          : ''
                      }${removeNull(
                        getStringFormateData(res.data, 'brightness'),
                        '',
                      )} `,
                    },
                    {
                      key: 'Refresh Rate',
                      value: removeNull(
                        getStringFormateData(res.data, 'display_refresh_rate'),
                        ' Hz',
                      ),
                    },
                    {
                      key: 'Size',
                      value: removeNull(getDisplaySpecification(res.data)),
                    },
                    {
                      key: 'Color',
                      value: removeNull(
                        getColorAndSoundData(res.data, 'display_colors'),
                        '',
                      ),
                    },
                    {
                      key: 'Resolution',
                      value: `${removeNull(
                        getStringFormateData(
                          res.data,
                          'display_resolution_width',
                        ),
                        ' x',
                      )} ${removeNull(
                        getStringFormateData(
                          res.data,
                          'display_resolution_height',
                        ),
                        ' pixels',
                      )}${
                        removeNull(
                          getStringFormateData(
                            res.data,
                            'display_resolution_height',
                          ),
                          ' pixels',
                        )
                          ? removeNull(
                              getStringFormateData(
                                res.data,
                                'display_resolution_screen_ratio_numerator',
                              ),
                              ':',
                            )
                            ? ', '
                            : removeNull(
                                getStringFormateData(
                                  res.data,
                                  'display_resolution_screen_ratio_denominator',
                                ),
                                ' ratio ',
                              )
                          : ''
                      } ${removeNull(
                        getStringFormateData(
                          res.data,
                          'display_resolution_screen_ratio_numerator',
                        ),
                        ':',
                      )}${removeNull(
                        getStringFormateData(
                          res.data,
                          'display_resolution_screen_ratio_denominator',
                        ),
                        ' ratio ',
                      )}`,
                    },
                    {
                      key: 'Pixel Density',
                      value: removeNull(
                        getStringFormateData(
                          res.data,
                          'display_resolution_pixel_density',
                        ),
                        ' ppi',
                      ),
                    },
                    {
                      key: 'Cover Display',
                      value: getCoverDisplaySpecification(res.data),
                    },
                    {
                      key: 'Protection',
                      value: removeNull(
                        getDisplayProtectionInformation(res.data),
                      ),
                    },
                    {
                      key: 'Always on Display',
                      value: `${
                        getStringFormateData(res.data, 'display_AOD')
                          ? 'Yes'
                          : getStringFormateData(res.data, 'display_AOD') ===
                              false
                            ? 'No'
                            : ''
                      }`,
                    },
                    {
                      key: 'Other',
                      value: `${removeNull(
                        getExtraOtherDataForDisplay(res.data, 'display_other'),
                        '',
                      )}`,
                    },
                  ]
                : res.key === 'platform'
                  ? [
                      {
                        key: 'OS',
                        value: removeNull(getOSSpecification(res.data)),
                      },
                      {
                        key: 'Chipset',
                        value: removeNull(getChipSetSpecification(res.data)),
                      },
                      {
                        key: 'CPU',
                        value: `${removeNull(
                          getPlatformCPUInformation(res.data, 'platform_cpu'),
                          '',
                        )}`,
                      },
                      {
                        key: 'GPU',
                        value: `${removeNull(
                          getStringFormateData(res.data, 'platform_gpu_series'),
                          ' ',
                        )}${removeNull(
                          getStringFormateData(res.data, 'platform_gpu_model'),
                          ' ',
                        )}${removeNull(
                          getStringFormateData(res.data, 'platform_gpu_core'),
                          '',
                        )}`,
                      },
                    ]
                  : res.key === 'storage'
                    ? [
                        {
                          key: 'Internal Memory',
                          value: `${removeNull(
                            getMemoryInformation(res.data, 'memory_internal'),
                            '',
                          )}`,
                        },
                        {
                          key: 'Card slot',
                          value: `${removeNull(
                            getStringFormateData(res.data, 'memory_card_slot'),
                            '',
                          )}`,
                        },
                        {
                          key: 'Phone book',
                          value: `${
                            getStringFormateData(res.data, 'memory_phonebook')
                              ? 'Yes'
                              : getStringFormateData(
                                    res.data,
                                    'memory_phonebook',
                                  ) === false
                                ? 'No'
                                : ''
                          }`,
                        },
                        {
                          key: 'Call records',
                          value: `${
                            getStringFormateData(
                              res.data,
                              'memory_call_records',
                            )
                              ? 'Yes'
                              : getStringFormateData(
                                    res.data,
                                    'memory_call_records',
                                  ) === false
                                ? 'No'
                                : ''
                          }`,
                        },
                        {
                          key: 'Storage Type',
                          value: `${removeNull(
                            getStringFormateData(
                              res.data,
                              'memory_storage_type',
                            ),
                            '',
                          )}`,
                        },
                      ]
                    : res.key === 'main'
                      ? [
                          {
                            key: 'Rear Camera',
                            value: getCameraDetails(res, index),
                          },
                          {
                            key: 'Features',
                            value: `${removeNull(
                              getFilteredCameraAndColorFeatures(
                                res.data,
                                'main_camera_features',
                              ),
                              '',
                            )}`,
                          },
                          {
                            key: 'Video',
                            value: `${removeNull(
                              getVideoInformation(
                                res.data,
                                'main_camera_video',
                              ),
                              '',
                            )}`,
                          },
                        ]
                      : res.key === 'selfie'
                        ? [
                            {
                              key: `${removeNull(
                                getStringFormateData(
                                  res.data,
                                  'selfie_camera_type',
                                ),
                                '',
                              )}`,
                              value: `${getSelfieCameraDetails(res.data, 'selfie_camera_data')}`,
                            },
                            {
                              key: 'Cover Camera',
                              value: `${getSelfieCameraDetails(res.data, 'cover_camera')}`,
                            },
                            {
                              key: 'Features',
                              value: `${removeNull(
                                getFilteredCameraAndColorFeatures(
                                  res.data,
                                  'selfie_camera_features',
                                ),
                                '',
                              )}`,
                            },
                            {
                              key: 'Video',
                              value: `${removeNull(
                                getVideoInformation(
                                  res.data,
                                  'selfie_camera_video',
                                ),
                                '',
                              )}`,
                            },
                          ]
                        : res.key === 'sound'
                          ? [
                              {
                                key: 'Loudspeaker',
                                value: `${
                                  getStringFormateData(
                                    res.data,
                                    'sound_loudspeaker_has_speakers',
                                  )
                                    ? 'Yes'
                                    : getStringFormateData(
                                          res.data,
                                          'sound_loudspeaker_has_speakers',
                                        ) === false
                                      ? 'No'
                                      : ''
                                }${
                                  removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'sound_loudspeaker_speaker_type',
                                    ),
                                    '',
                                  )
                                    ? getStringFormateData(
                                        res.data,
                                        'sound_loudspeaker_has_speakers',
                                      ) !== null
                                      ? ', '
                                      : ''
                                    : ''
                                }${removeNull(
                                  getStringFormateData(
                                    res.data,
                                    'sound_loudspeaker_speaker_type',
                                  ),
                                  '',
                                )}`,
                              },
                              {
                                key: 'Sound jack',
                                value: `${
                                  getStringFormateData(
                                    res.data,
                                    'sound_jack_present',
                                  )
                                    ? 'Yes'
                                    : getStringFormateData(
                                          res.data,
                                          'sound_jack_present',
                                        ) === false
                                      ? 'No'
                                      : ''
                                }${
                                  removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'sound_jack_size',
                                    ),
                                    '',
                                  )
                                    ? getStringFormateData(
                                        res.data,
                                        'sound_jack_present',
                                      ) ||
                                      getStringFormateData(
                                        res.data,
                                        'sound_jack_present',
                                      ) === false
                                      ? ', '
                                      : ''
                                    : ''
                                }${removeNull(
                                  getStringFormateData(
                                    res.data,
                                    'sound_jack_size',
                                  ),
                                  '',
                                )}`,
                              },
                              {
                                key: 'Other',
                                value: `${removeNull(
                                  getColorAndSoundData(res.data, 'sound_other'),
                                  '',
                                )}`,
                              },
                            ]
                          : res.key === 'comms'
                            ? [
                                {
                                  key: 'WLAN',
                                  value: `${communicationDataFormatter(
                                    res.data,
                                    [
                                      { comms_wlan_wifiType: null },
                                      {
                                        comms_wlan_dualBandEnabled: 'dual-band',
                                      },
                                      {
                                        comms_wlan_hotspotEnabled: 'hotspot',
                                      },
                                      {
                                        comms_wlan_wifiDirectEnabled:
                                          'Wi-Fi Direct',
                                      },
                                      { comms_wlan_dlnaEnabled: 'DLNA' },
                                      { comms_wlan_upnpEnabled: 'UPNP' },
                                      {
                                        comms_wlan_triBandEnabled: 'tri-band',
                                      },
                                    ],
                                  )}`,
                                },
                                {
                                  key: 'Bluetooth',
                                  value: `${communicationDataFormatter(
                                    res.data,
                                    [
                                      { comms_bluetooth_version: null },
                                      { comms_bluetooth_LE: 'LE' },
                                      { comms_bluetooth_EDR: 'EDR' },
                                      { comms_bluetooth_A2DP: 'A2DP' },
                                      { comms_bluetooth_APTX: 'APTX' },
                                      {
                                        comms_bluetooth_APTX_HD: 'aptX HD',
                                      },
                                      {
                                        comms_bluetooth_APTX_Adaptive:
                                          'aptX Adaptive',
                                      },
                                      {
                                        comms_bluetooth_APTX_Lossless:
                                          'aptX Lossless',
                                      },
                                      { comms_bluetooth_profile: null },
                                      { comms_bluetooth_support: null },
                                      { comms_bluetooth_LHDC: 'LHDC' },
                                    ],
                                  )}`,
                                },
                                {
                                  key: 'Positioning',
                                  value: `${removeNull(
                                    getCommunicationPositioningInformation(
                                      res.data,
                                      'comms_positioning',
                                    ),
                                    '',
                                  )}`,
                                },
                                {
                                  key: 'NFC',
                                  value: `${
                                    getStringFormateData(res.data, 'comms_nfc')
                                      ? 'Yes'
                                      : getStringFormateData(
                                            res.data,
                                            'comms_nfc',
                                          ) === false
                                        ? 'No'
                                        : ''
                                  }`,
                                },
                                {
                                  key: 'Radio',
                                  value: `${
                                    getStringFormateData(
                                      res.data,
                                      'comms_radio',
                                    )
                                      ? 'Yes'
                                      : getStringFormateData(
                                            res.data,
                                            'comms_radio',
                                          ) === false
                                        ? 'No'
                                        : ''
                                  }`,
                                },
                                {
                                  key: 'USB',
                                  value: ` ${removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'comms_usb_type',
                                    ),
                                    '',
                                  )}${
                                    removeNull(
                                      getStringFormateData(
                                        res.data,
                                        'comms_usb_type',
                                      ),
                                      '',
                                    )
                                      ? removeNull(
                                          getStringFormateData(
                                            res.data,
                                            'comms_usb_otg',
                                          ),
                                          'OTG',
                                        )
                                        ? ', '
                                        : removeNull(
                                              getStringFormateData(
                                                res.data,
                                                'comms_usb_lighting',
                                              ),
                                              'Lightning',
                                            )
                                          ? ', '
                                          : ''
                                      : ''
                                  } ${removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'comms_usb_otg',
                                    ),
                                    'OTG',
                                  )}${
                                    getStringFormateData(
                                      res.data,
                                      'comms_usb_otg',
                                    )
                                      ? getStringFormateData(
                                          res.data,
                                          'comms_usb_lighting',
                                        )
                                        ? ', '
                                        : ''
                                      : ''
                                  }${removeNull(
                                    getStringFormateData(
                                      res.data,
                                      'comms_usb_lighting',
                                    ),
                                    'Lightning',
                                  )}`,
                                },
                                {
                                  key: 'Infrared port',
                                  value: `${
                                    getStringFormateData(
                                      res.data,
                                      'comms_infrared_port',
                                    )
                                      ? 'Yes'
                                      : getStringFormateData(
                                            res.data,
                                            'comms_infrared_port',
                                          ) === false
                                        ? 'No'
                                        : ''
                                  }`,
                                },
                              ]
                            : res.key === 'sensors'
                              ? [
                                  {
                                    key: 'Features',
                                    value: `${removeNull(
                                      getDeviceFeatureAndSensors(
                                        res.data,
                                        'features_sensors',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Device',
                                    value: `${removeNull(
                                      getDeviceFeatureAndSensors(
                                        res.data,
                                        'device_features',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Browser',
                                    value: `${removeNull(
                                      getStringFormateData(
                                        res.data,
                                        'feature_browser',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Messaging',
                                    value: `${removeNull(
                                      getStringFormateData(
                                        res.data,
                                        'feature_messaging',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Games',
                                    value: `${removeNull(
                                      getStringFormateData(
                                        res.data,
                                        'feature_games',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Java',
                                    value: `${removeNull(
                                      getStringFormateData(
                                        res.data,
                                        'feature_java',
                                      ),
                                      '',
                                    )}`,
                                  },
                                  {
                                    key: 'Other',
                                    value: `${removeNull(
                                      getDeviceOtherFeatures(
                                        res.data,
                                        'features_other',
                                      ),
                                      '',
                                    )}`,
                                  },
                                ]
                              : res.key === 'battery'
                                ? [
                                    {
                                      key: 'Type',
                                      value: `${removeNull(
                                        getStringFormateData(
                                          res.data,
                                          'battery_type',
                                        ),
                                        '',
                                      )} `,
                                    },
                                    {
                                      key: 'Capacity',
                                      value: `${removeNull(
                                        getStringFormateData(
                                          res.data,
                                          'battery_capacity',
                                        ),
                                        ' mAh',
                                      )} `,
                                    },
                                    {
                                      key: 'Removable',
                                      value: `  ${
                                        getStringFormateData(
                                          res.data,
                                          'battery_is_removable',
                                        ) === true
                                          ? 'Yes'
                                          : getStringFormateData(
                                                res.data,
                                                'battery_is_removable',
                                              ) === false
                                            ? 'No'
                                            : ''
                                      } 
              `,
                                    },
                                    {
                                      key: 'Charging',
                                      value: getChargingSpecification(res.data),
                                    },
                                    {
                                      key: 'Stand By',
                                      value: `${removeNull(
                                        getStringFormateData(
                                          res.data,
                                          'battery_stand_by',
                                        ),
                                        '',
                                      )}`,
                                    },
                                    {
                                      key: 'Talk Time',
                                      value: `${removeNull(
                                        getStringFormateData(
                                          res.data,
                                          'battery_talk_time',
                                        ),
                                        '',
                                      )}`,
                                    },
                                    {
                                      key: 'Music Play',
                                      value: `${removeNull(
                                        getStringFormateData(
                                          res.data,
                                          'battery_music_play',
                                        ),
                                        '',
                                      )}`,
                                    },
                                  ]
                                : res.key === 'misc'
                                  ? [
                                      {
                                        key: 'Colors',
                                        value: `${getFilteredCameraAndColorFeatures(
                                          res.data,
                                          'misc_colors',
                                        )}`,
                                      },
                                      {
                                        key: 'Models',
                                        value: `${getFilteredCameraAndColorFeatures(
                                          res.data,
                                          'misc_models',
                                        )}`,
                                      },
                                      {
                                        key: 'SAR',
                                        value: `${removeNull(
                                          getStringFormateData(
                                            res.data,
                                            'misc_sar_head',
                                          ),
                                          '',
                                        )} ${removeNull(
                                          getStringFormateData(
                                            res.data,
                                            'misc_sar_body',
                                          ),
                                          '',
                                        )}`,
                                      },
                                      {
                                        key: 'SAR EU',
                                        value: `${removeNull(
                                          getStringFormateData(
                                            res.data,
                                            'misc_sar_eu_head',
                                          ),
                                          '      ',
                                        )} ${getStringFormateData(
                                          res.data,
                                          'misc_sar_eu_body',
                                        )}`,
                                      },
                                    ]
                                  : res.key === 'overview'
                                    ? [
                                        {
                                          key: 'Display',
                                          value: removeNull(
                                            displayInformationForOverviewField(
                                              displayData,
                                            ),
                                          ),
                                        },
                                        {
                                          key: 'Camera',
                                          value: removeNull(
                                            cameraInformationForOverviewField(
                                              cameraData,
                                              selfieCamera,
                                            ),
                                          ),
                                        },
                                        {
                                          key: 'Hardware',
                                          value: removeNull(
                                            getStringFormateData(
                                              softwareAndHardware,
                                              'chipset_processor',
                                            ),
                                          )
                                            ? `${removeNull(
                                                getStringFormateData(
                                                  softwareAndHardware,
                                                  'chipset_processor',
                                                ),
                                                '',
                                              )} ${removeNull(
                                                getStringFormateData(
                                                  softwareAndHardware,
                                                  'chipset_model',
                                                )
                                                  ? removeNull(
                                                      getStringFormateData(
                                                        softwareAndHardware,
                                                        'chipset_model',
                                                      ),
                                                    )
                                                  : '',
                                                '',
                                              )}`
                                            : '-',
                                        },
                                        {
                                          key: 'OS',
                                          value: removeNull(
                                            OSInformationForOverviewField(
                                              softwareAndHardware,
                                            ),
                                          ),
                                        },
                                        {
                                          key: 'Battery',
                                          value: removeNull(
                                            batteryInformationForOverviewField(
                                              batteryData,
                                            ),
                                          ),
                                        },
                                        {
                                          key: 'Storage',
                                          value: `${removeNull(
                                            getMemoryData(
                                              memoryData,
                                              'memory_internal',
                                            ),
                                            '',
                                          )}`,
                                        },
                                      ]
                                    : res.key === 'performance'
                                      ? getPerformanceData(res.data).map(
                                          (item: any) => ({
                                            key: item.key,
                                            value: getFormattedData(item.value),
                                          }),
                                        )
                                      : '',
  }));
};
