import Header from '@/CommonComponent/header';
import Footer from '@/CommonComponent/footer';
import { Box, Container, Grid } from '@mui/material';
import { useRef, ReactNode, RefObject } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { ROUTE } from '@/Interface/interface';

const BreadCrumbs = dynamic(() => import('@/CommonComponent/breadCrumbs'), {
  ssr: true,
});
interface LayoutProps {
  children: ReactNode;
}

export default function Layout({
  children,
  urlPath,
  singleDeviceData,
  compareText,
  popularPhoneData,
}: LayoutProps | any) {
  const router = useRouter();
  const myRef: RefObject<HTMLDivElement> = useRef(null);
  const getData = (res: any) => {
    if (res === undefined) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <Header myRef={myRef} />
      {router.pathname !== '/blog' &&
      router.pathname !== '/' &&
      router.pathname !== ROUTE.ERROR_PAGE &&
      (getData(urlPath) ? true : urlPath !== undefined) &&
      (getData(singleDeviceData?.data)
        ? true
        : singleDeviceData?.data !== null) &&
      (getData(compareText) ? true : !compareText?.includes(null)) &&
      (getData(popularPhoneData?.data)
        ? true
        : popularPhoneData?.data !== null) ? (
        <Grid sx={{ backgroundColor: '#F0F0F0' }} paddingY={{ xs: 0.8, md: 2 }}>
          <BreadCrumbs />
        </Grid>
      ) : (
        ''
      )}

      <Box className="main-content section-bottom-gapping" minHeight={400}>
        <Container>{children}</Container>
      </Box>
      {router.pathname !== ROUTE.ERROR_PAGE &&
      (getData(urlPath) ? true : urlPath !== undefined) &&
      (getData(singleDeviceData?.data)
        ? true
        : singleDeviceData?.data !== null) &&
      (getData(compareText) ? true : !compareText?.includes(null)) ? (
        <Footer />
      ) : (
        ''
      )}
    </div>
  );
}
