import axios from 'axios';
import { printLogs } from './Service';
const Config = async (
  url: string,
  method: string,
  data: {},
  isTrue: boolean,
  countryName?: any,
) => {
  const API_BASE_URL = !isTrue
    ? `${process.env.API_BASE_URL}/api`
    : process.env.API_BASE_URL;
  try {
    const response = await axios({
      url: API_BASE_URL + url,
      method: method,
      data: data,
      withCredentials: true,
      headers: {
        Cookie: countryName,
      },
    });
    return response;
  } catch (e: any) {
    printLogs('error', e);
    return e;
  }
};

export default Config;
